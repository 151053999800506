
import { defineComponent, reactive, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import HeaderTit from '@/components/HeaderTit.vue'
import { getStorage } from '../commons/SessionStorageUtils'
import AxiosAdapter from '../request/AxiosAdapter'
import GetImageConversion from '../commons/ImageConversion'
import { ElMessage } from 'element-plus'
import { getRecord } from '../commons/Utils'
import { throwCustomFunction } from '../commons/ErrorHandle'
import { acceptImage } from '../commons/unit'

export default defineComponent({
  name: 'NameCertification',
  components: { HeaderTit },
  setup() {
    const router = useRouter()
    const getUrl = process.env.VUE_APP_BASE_API
    const urls = reactive({
      frontUrl: '',
      reverseUrl: ''
    })
    const acceptImageUrl = ref(acceptImage)
    const submit = async () => {
      getRecord('个人中心,身份证上传提交，正面:' + urls.frontUrl + '反面:' + urls.reverseUrl, true)
      if (!urls.frontUrl) {
        throwCustomFunction('请上传身份证正面')
      }
      if (!urls.reverseUrl) {
        throwCustomFunction('请上传身份证反面')
      }
      
      await AxiosAdapter.post({url: '/webElectronic/electronicUserInfoAuth', data: urls})
      ElMessage.success({
        type: 'success',
        message: '保存成功',
        duration: 2000
      })
      router.push({
        path: '/personal-center'
      })
    }
    const gotoPage = () => {
      router.back()
    }
    const gotoModifyPage = () => {
      router.push({
        path: '/modify-password'
      })
    }
    const fontSuccess = (res: any) => {
      urls.frontUrl = res.value && res.value[0]
    }
    const backSuccess = (res: any) => {
      urls.reverseUrl = res.value && res.value[0]
    }
    const beforeUpload = async (file: any) => {
      return new Promise((resolve) => {
        const data = GetImageConversion(file)
        return resolve(data)
      })
    }
    
    onMounted( async () => {
      const data: any = await AxiosAdapter.get({url: '/webElectronic/getIdentityPhoto'})
      urls.frontUrl = data.frontUrl
      urls.reverseUrl = data.reverseUrl
    })
    getRecord('入口个人中心进入上传身份证页面', true)
    return { submit, gotoPage, gotoModifyPage, beforeUpload, fontSuccess, getStorage, getUrl, backSuccess, urls, acceptImageUrl }
  }
})
