import { throwCustomFunction } from "./ErrorHandle";
import * as imageConversion from 'image-conversion';
import { ElLoading, ElMessage } from 'element-plus'
let loading: any
export default function GetImageConversion(imageFile: any) {
    if (imageFile.size <= 0) {
        ElMessage.error({
            type: 'error',
            message: '上传图片大小不小于0kb',
            duration: 3000
        })
        return throwCustomFunction('上传图片大小不小于0kb')
    }
    loading = ElLoading.service({
        lock: true,
        text: "查询中请稍等",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
    })
    return imageConversion.compressAccurately(imageFile, 300).then((res: any) => {
        loading.close()
        return res
    }).catch((err: any) => {
        loading.close()
        throwCustomFunction(err);
    })
}

