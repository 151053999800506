import AxiosAdapter from '@/request/AxiosAdapter'
import { getStorage } from './SessionStorageUtils'

export interface AntiShakeVo {
    fn: () => void;
    delay?: number;
}

export type NoArgNoReturnType = () => void

// 防抖功能
export function antiShakeFn(vo: AntiShakeVo): NoArgNoReturnType;
export function antiShakeFn(vo: AntiShakeVo): NoArgNoReturnType {
    let timer: NodeJS.Timeout | null
    return () => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            clearTimeout(timer!)
            timer = null
            vo.fn()
        }, vo.delay || 10);
    }
}

// 节流功能


//添加record
export async function getRecord(msg: string, status: boolean) {
    const params = {
        telephone: getStorage('Key') || '',
        msg: msg,
        status: status
    }
    await AxiosAdapter.get({ url: '/webElectronic/logRecord', data: params })
}

export async function getRecordId(electronicId: any, msg: string, status: boolean) {
    const params = {
        electronicId: electronicId,
        msg: msg,
        status: status
    }
    await AxiosAdapter.get({ url: '/webElectronic/logRecord', data: params })
}


